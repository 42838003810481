import { render, staticRenderFns } from "./activity-details-nolive.vue?vue&type=template&id=8b78b7d2&scoped=true&"
import script from "./activity-details-nolive.vue?vue&type=script&lang=js&"
export * from "./activity-details-nolive.vue?vue&type=script&lang=js&"
import style0 from "./activity-details-nolive.vue?vue&type=style&index=0&id=8b78b7d2&lang=scss&scoped=true&"
import style1 from "./activity-details-nolive.vue?vue&type=style&index=1&id=8b78b7d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b78b7d2",
  null
  
)

export default component.exports